import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image";

import Head from "../components/head"

import Layout from "../components/layout"

export const query = graphql`
    query($slug: String!) {
        allWpPage(filter: { slug: { eq: $slug } }) {
          nodes {
            title
            slug
            content
            featuredImage {
                node {
                    localFile{
                        childImageSharp {
                            fluid(maxHeight: 1180) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                  sourceUrl
                  title
                }
             }
          }
        }
      }
    `

const Page = ( props ) => {
    const { data } = props
    const post = data.allWpPage.nodes[0]

    return (
        <Layout css_class="post">
            <Head title={post.title} />
            <main>
                <div className="container">
                    <section>
                        <div className="row">
                            <h1>{post.title}</h1>
                            {post.featuredImage &&
                            <Img fluid={post.featuredImage.node.localFile.childImageSharp.fluid} key={post.featuredImage.node.localFile.childImageSharp.fluid.src} />}
                            <div
                                className="content"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.content,
                                }}
                            ></div>
                        </div>
                    </section>
                </div>
            </main>
        </Layout>
    )
}

export default Page
